import React from 'react';
import { UnlockOutlined, LockOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Menu, Popover, Avatar, Dropdown, Button, Space } from 'antd';
import { useState } from 'react';
import StudentWrapper from './StudentWrapper.styles';
import images from '../../assets/images';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import studentAction from '@iso/redux/student/actions'
import WebsiteService from "../../../src/services/frontend/WebsiteService";
import TopbarDropdownWrapper from "./TopbarDropdown.styles";
import IntlMessages from '@iso/components/utility/intlMessages';

import "../../pages/frontend/12hour/CSS/popoverstyle.css";
import AppHelper from '../../helpers/AppHelper';

const { Header } = Layout;

export default function TopbarStudent() {

  const [current, setCurrent] = useState('mail');
  const [visible, setVisibility] = useState(false);
  const onClick = (e) => {
    setCurrent(e.key);  
  };
  const history = useHistory();
  const { signout } = studentAction;
  const dispatch = useDispatch();
  const isDotSite = AppHelper.getSiteType() == 'dot';

  function handleVisibleChange() {
    setVisibility(visible => !visible);
  }

  const { user } = useSelector((state) => state.userReducers);
  let pathname = window.location.pathname;

  const items = [
    {
      label: <a href='javascript:void(0);' onClick={() => setVisibility(visible => !visible)} > {user ? `${user.first_name} ${user.last_name}` : "" }</a>,
      key: 'mail',
      icon: <UserOutlined/>,
    },
    {
      label: (
        <Link to={"/"} onClick={() => dispatch(signout())}>
          Sign Out
        </Link>
      ),
      key: 'alipay',
      icon: <UnlockOutlined />,
    },
  ];

  const websiteService = new WebsiteService();
  const [websiteDetail, setWebsiteDetail] = React.useState({})
  const [loading, setLoading] = React.useState(false);
  const getWebsiteDetail = () => {
    setLoading(true);
    websiteService.getWebsiteDetail().then(res => {
      setWebsiteDetail(res);
      localStorage.setItem("site_type", res.site_type == 'dot' ? 'metro_dot' : res.site_type);
    }).finally(() => {
      setLoading(false);
    })
  }

  React.useEffect(() => {
    getWebsiteDetail();
  }, [])

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link className="isoDropdownLink" to='/account'>
        <IntlMessages id="Student.Topbar.myAccount" />
      </Link>
      <Link className="isoDropdownLink" to='/edit-profile'>
        <IntlMessages id="Student.Topbar.editProfile" />
      </Link>
      <Link className="isoDropdownLink" to='/payment-transaction'>
        <IntlMessages id="Student.Topbar.myTransaction" />
      </Link>
    </TopbarDropdownWrapper>
  );

  

  return (
    <>
    <StudentWrapper>
      <Header className="header-wrapper">
        <div className="container">
            <Row align="middle">
              <Col span={12}>
                <a href="#" className="brand-logo">
                  {!loading && <img src={websiteDetail?.website_logo && websiteDetail?.website_logo!='' ? websiteDetail?.website_logo : images.logo} alt="" />}
                </a>
              </Col>
              {(!user && pathname !== "/login" && pathname !== "/") && <Col span={12} style={{display: "flex", justifyContent: "end", alignItems: "end"}} >
                  <div>
                    <a href='javascript:void(0)' className='text-white d-flex align-items-center gap-2 topbar'><UserOutlined style={{fontSize: "23px"}}/> 
                    <span className='d-flex align-items-center gap-1'>Welcome Guest </span></a>
                  </div>
                    <Link to='/' className='text-white d-flex align-items-center gap-2 mx-3 topbar'>
                      <LockOutlined style={{fontSize: "23px"}}/> Sign In
                    </Link>
              </Col>}
             {(user && pathname !== "/login" && pathname !== "/") && <Col span={12} style={{display: "flex", justifyContent: "end", alignItems: "end"}} >
                  <Popover trigger="click" placement="bottom" open={visible} content={content} onVisibleChange={handleVisibleChange}>
                    <a href='javascript:void(0)' className='text-white d-flex align-items-center gap-2 topbar'><UserOutlined style={{fontSize: "23px"}}/> 
                    <span className='d-flex align-items-center gap-1'>{user ? `${user.name}` : "" } <DownOutlined /></span></a>
                  </Popover>
                    <Link to='/' className='text-white d-flex align-items-center gap-2 mx-3 topbar' onClick={() => {
                      dispatch(signout());
                      dispatch({type: "STUDENT_LOGOUT"});
                    }}>
                    <UnlockOutlined style={{fontSize: "23px"}}/> Sign Out
                    </Link>
              </Col>}
            </Row>
        </div>
      </Header>
    </StudentWrapper>
   </>
  );
}
