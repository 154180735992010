// eslint-disable-next-line import/no-anonymous-default-export
export default {
  siteName: 'WebWizards',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} Copyright. All rights reserved.`,
  studentCR: `Copyright © ${new Date().getFullYear()} - All Rights Reserved.`,
  enableAnimatedRoute: false,
  apiUrl: process.env.REACT_APP_API_URL,
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};